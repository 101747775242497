import request from '@/utils/request';
import { MediaObject } from '@/types/MediaObject';

const baseUri = '/media_objects';

export const createMediaObject = (data: any): Promise<MediaObject> => {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });

    return request({
        url: baseUri,
        method: 'POST',
        data: formData,
        timeout: 60000
    });
};

export const getMediaObject = (id: string): Promise<MediaObject> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const viewMediaObject = (id: string, params: object): Promise<string> =>
    request({
        url: `${baseUri}/${id}/view`,
        method: 'GET',
        params,
    });
